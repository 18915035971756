<template>
    <v-container>
        <v-row :align="formPages ? 'start' : 'center'" justify="start" no-gutters>
            <v-col cols="4">
                <kurcc-breadcrumbs/>
            </v-col>
            <v-col cols="8">
                <kurcc-form-submit-buttons :selected-categories-to-assign-to="selectedCategoriesToAssignTo"/>
            </v-col>
            <v-col v-if="formPages">
                <kurcc-form-heading/>
                <kurcc-question-page-list/>
                <kurcc-add-page-button/>
            </v-col>
            <v-col v-else>
                <v-progress-circular indeterminate/>
            </v-col>
            <v-col cols="auto">
                <kurcc-form-preview/>
                <kurcc-assign-form-to-categories :selected-categories-to-assign-to.sync="selectedCategoriesToAssignTo"/>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import {UPDATE_INSPECTION_FORM} from "@/modules/app/store/mutation-types";
import Form from "@/modules/forms/models/form";

export default {
    name: "KurccFormPage",
    props: ['form', 'form_id', 'cloned_form'],
    components: {
        KurccFormPreview: () => import('@/modules/forms/components/KurccFormPreview'),
        KurccBreadcrumbs: () => import('@/modules/forms/components/KurccBreadcrumbs'),
        KurccFormHeading: () => import('@/modules/forms/components/KurccFormHeading'),
        KurccQuestionPageList: () => import('@/modules/forms/components/question/KurccQuestionPageList'),
        KurccFormSubmitButtons: () => import('@/modules/forms/components/KurccFormSubmitButtons'),
        KurccAddPageButton: () => import('@/modules/forms/components/KurccAddPageButton'),
        KurccAssignFormToCategories: () => import('@/modules/forms/components/KurccAssignFormToCategories')
    },
    data() {
        return {
            selectedCategoriesToAssignTo: []
        }
    },
    computed: {
        formPages() {
            return this.$store.state.forms.form.pages
        }
    },
    methods: {
        fetchFormData() {
            this.$store.dispatch('getForm', {id: this.form_id})
                .then(res => {
                    this.$store.commit(UPDATE_INSPECTION_FORM, res)
                })
        },
    },
    created() {
        if (this.cloned_form) {
            this.$store.commit(UPDATE_INSPECTION_FORM, this.cloned_form)
        } else {
            if (this.form_id) {
                if (this.form)
                    this.$store.commit(UPDATE_INSPECTION_FORM, this.form)
                else this.fetchFormData()
            } else this.$store.commit(UPDATE_INSPECTION_FORM, new Form())
        }

    }
}
</script>
